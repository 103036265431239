import React, {createRef, useEffect} from 'react';
import {View, StyleSheet} from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import AuthService from '../services/AuthService';
import SiteIconSvg from '../components/svgs/SiteIconSvg';
import {setUser, setToken, setOrganization, setOrganizationRoleId, setInitialized, setPermissions} from '../store/auth/authActions';
import AppText from '../components/AppText';
import AppTextInput from '../components/AppTextInput';
import AppButton from '../components/AppButton';
import {basicStyles} from '../styles/basic';
import UnauthenticatedHeader from '../components/UnauthenticatedHeader';
import EStyleSheet from 'react-native-extended-stylesheet';
import TouchView from "../components/TouchView";

function Login(props) {
    const [email, updateEmail] = React.useState('');
    const [password, updatePassword] = React.useState('');
    const [error, updateError] = React.useState('');
    const {navigate} = props.navigation;
    const {setToken, setUser, setOrganization, setOrganizationRoleId, setInitialized, setPermissions} = props;

    async function login() {
        if (email && password) {
            try {
                const loginData = await AuthService.login(email, password);
                const {token, user, organizations} = loginData;
                const organization = organizations[0];
                const {role_id} = organization;

                await AsyncStorage.setItem('authToken', token);
                await AsyncStorage.setItem('organizationId', organization.organization_id);
                await AsyncStorage.setItem('roleId', role_id);

                setToken(token);
                setUser(user);
                setOrganization(organization);
                setOrganizationRoleId(role_id);
                setPermissions(await AuthService.getPermissions(organization.organization_id));
                setInitialized(true);

            } catch (error) {
                if (error?.response?.data?.meta?.message) {
                    updateError(error.response.data.meta.message);
                }
            }
        }
    }

    const secondInput = createRef();

    useEffect(() => {
        if (props.auth.initialized) {
            if (props.auth?.token && props.auth?.organization?.organization_id && props.auth?.organizationRoleId) {
                if (props.route?.params?.redirect) {
                    window.location = props.route?.params?.redirect;
                } else {
                    navigate('Authenticated');
                }
            }
        }
    }, [props.auth.initialized, props.auth.token, props.auth.organization?.organization_id, props.auth.organizationRoleId]);

    return (
        <View style={basicStyles.flexScale}>
            <UnauthenticatedHeader/>
            <View style={styles.pageWrapper}>
                <View style={styles.contentWrapper}>
                    <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 40}}>
                        <SiteIconSvg style={{marginRight: 10}}/>
                        <AppText style={styles.heading}>Log in to your Fluid Account</AppText>
                    </View>
                    <AppText>{error}</AppText>
                    <View style={styles.loginInputsContainer}>
                        <AppTextInput
                            label="Email"
                            value={email}
                            onChangeText={value => updateEmail(value)}
                            wrapperStyle={{marginBottom: 20}}
                            onSubmitEditing={() => secondInput.current.focus()}
                        />
                        <AppTextInput
                            ref={secondInput}
                            label="Password"
                            value={password}
                            onChangeText={value => updatePassword(value)}
                            secureTextEntry={true}
                            onSubmitEditing={login}
                            wrapperStyle={{marginBottom: 25}}
                        />
                    </View>
                    <AppButton
                        label="Login"
                        action={() => login()}
                        style={{
                            marginBottom: 10,
                            width: 145,
                        }}
                    />
                    <TouchView
                        style={{marginBottom: 20}}
                        action={() => navigate('PasswordReset', {})}
                    >
                        <AppText style={{fontSize: 12, color: '#CCCCCC'}}>Forgot password?</AppText>
                    </TouchView>
                    <TouchView
                        style={{marginBottom: 20}}
                        action={() => window.open('https://fluid.services/terms-of-service/', '_blank')}
                    >
                        <AppText style={{fontSize: 12, color: '#CCCCCC'}}>Terms of Service and Privacy Policy</AppText>
                    </TouchView>
                </View>
            </View>
        </View>
    );
}


const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        setToken,
        setUser,
        setOrganization,
        setOrganizationRoleId,
        setInitialized,
        setPermissions
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Login);


const styles = EStyleSheet.create({
    logoText: {
        fontSize: 19,
        color: '#000000',
    },
    pageWrapper: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F8FAFD',
        paddingRight: 50,
        paddingLeft: 50,
    },
    contentWrapper: {
        width: '100%',
        maxWidth: 852,
        backgroundColor: '#FFFFFF',
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#E2E9F4',
        alignItems: 'center',
        paddingTop: 60,
        paddingLeft: 100,
        paddingRight: 100
    },
    heading: {
        fontFamily: 'SourceSansPro-Bold',
        color: '#0B2774',
        fontSize: 34,
    },
    loginInputsContainer: {
        width: '100%',
        maxWidth: 560,
    },
    loginButton: {
        backgroundColor: '#0065DB',
        borderRadius: 4,
        height: 50,
        width: 145,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 50,
    },
    loginButtonText: {
        color: '#FFFFFF',
        fontSize: 15,
    },
    '@media (max-width: 768)': {
        contentWrapper: {
            marginTop: 160,
            marginBottom: 160
        },
        heading: {
            fontSize: 24
        }
    },
    '@media (max-width: 650)': {
        pageWrapper: {
            paddingRight: 15,
            paddingLeft: 15,
        },
        contentWrapper: {
            marginTop: 100,
            marginBottom: 100,
            paddingRight: 20,
            paddingLeft: 20,
        },
        heading: {
            fontSize: 19
        }
    }
});
