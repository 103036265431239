import Http from "../utilities/Http";
import {API_ROOT} from "../config";

export default {
    async login(email, password) {
        let response = await Http().post(API_ROOT + '/auth/login', {
            email,
            password,
        });

        return response.data;
    },
    async logout() {
        await Http().post(API_ROOT + '/auth/logout');
    },
    async validate() {
        let response = await Http().get(API_ROOT + '/auth/validate');

        return response.data.user;
    },
    async changePassword(email, password, updatedPassword, updatedPasswordConfirm) {
        let response = await Http().post(API_ROOT + '/auth/changePassword', {
            email,
            password,
            updatedPassword,
            updatedPasswordConfirm,
        });

        return response.data;
    },
    async getPermissions(organizationId) {
        let response = await Http().get(API_ROOT + '/auth/get-permissions/' + organizationId);

        return response.data.permissions;
    },
    async emulateUser(userId, organizationId) {
        let response = await Http().get(API_ROOT + '/auth/emulate-user/' + userId + '/' + organizationId);

        return response.data;
    },
    async sendPasswordCode(email) {
        await Http().post(API_ROOT + '/auth/send-password-code/', {
            email
        });
    },
    async validatePasswordCode(passwordCode) {
        await Http().post(API_ROOT + '/auth/validate-password-code/', {
            passwordCode
        });
    },
    async resetPassword(passwordCode, password, passwordConfirm) {
        await Http().post(API_ROOT + '/auth/reset-password/', {
            passwordCode,
            password,
            passwordConfirm,
        });
    }
};
